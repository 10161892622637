export default class TravelDetail {
  constructor() {
    this.travelDetailType = null;
    this.travelDetailSubType = null;
    this.beginDate = null;
    this.endDate = null;
    this.isRentCar = false;
    this.isShuttle = false;
    this.comment = null;
  }
}
