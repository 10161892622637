<template>
  <v-row>
    <v-col cols="12">
      <v-subheader class="px-0 font-weight-bold subheading">
        {{ $t("hr.travel.travel_details") }}
        <v-divider class="ml-4" />
        <v-btn
          :disabled="disableCreateButton || readonly"
          fab
          elevation="0"
          class="ml-2"
          x-small
          color="primary"
          @click="innerValue.unshift(new TravelDetail())"
        >
          <v-icon size="24">mdi-plus</v-icon>
        </v-btn>
      </v-subheader>
    </v-col>
    <v-col cols="12">
      <p v-if="innerValue.length === 0" class="text-center body-2">
        {{ $t("hr.travel.no_detail_item") }}
      </p>
    </v-col>
    <v-col cols="12">
      <travel-detail-item
        v-for="(travelDetail, index) in innerValue"
        :ref="`travelDetailItem${index}`"
        class="mb-3"
        :key="index"
        v-model="innerValue[index]"
        :index="index"
        :active-index="activeIndex"
        :deleted-index="deletedIndex"
        :disabled="disableCreateButton"
        :readonly="readonly"
        :loading="loading"
        :deleting="deleting"
        :min-date="minDate"
        :max-date="maxDate"
        @create:item="onCreateItem(index, $event)"
        @update:item="onUpdateItem(index, $event)"
        @delete:item="onDeleteItem(index, $event)"
        @edit:click="activeIndex = index"
        @cancel:click="activeIndex = -1"
      />
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2020-12-29 08:09:28
   * @modify date 2020-12-29 08:09:28
   * @desc TravelDetails
   */
  import TravelDetail from "../model/TravelDetail";

  export default {
    name: "TravelDetails",
    components: {
      TravelDetailItem: () => import("./TravelDetailItem")
    },
    props: {
      value: Array,
      onDetail: Boolean,
      travelId: String,
      readonly: Boolean,
      minDate: String,
      maxDate: String
    },
    data: vm => ({
      temporaryTravelDetail: new TravelDetail(),
      activeIndex: -1,
      deletedIndex: -1,
      TravelDetail,
      loading: false,
      deleting: false
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      },
      disableCreateButton() {
        return this.innerValue.some(item => !item.id) || this.activeIndex > -1;
      }
    },
    methods: {
      onCreateItem(index, item) {
        if (this.onDetail) {
          this.loading = true;
          this.$api.travelService
            .createDetail(this.travelId, item)
            .then(response => {
              if (response.status === 201) {
                item.id = response.data.data;
                this.innerValue.splice(index, 1, item);
                this.$emit("update:detail");
              }
            })
            .catch(e => this.$helpers.showNotification(e.message))
            .finally(() => (this.loading = false));
        } else {
          this.innerValue.splice(index, 1, item);
        }
      },
      onUpdateItem(index, item) {
        if (this.onDetail) {
          this.loading = true;
          this.$api.travelService
            .updateDetail(this.travelId, item)
            .then(response => {
              if (response.status === 200) {
                this.innerValue.splice(index, 1, item);
                this.activeIndex = -1;
                this.$emit("update:detail");
              }
            })
            .catch(e => this.$helpers.showNotification(e.message))
            .finally(() => (this.loading = false));
        } else {
          this.innerValue.splice(index, 1, item);
          this.activeIndex = -1;
        }
      },
      onDeleteItem(index, itemId) {
        if (this.onDetail && itemId) {
          this.deletedIndex = index;
          this.deleting = true;
          this.$api.travelService
            .deleteDetail(this.travelId, itemId)
            .then(response => {
              if (response.status === 204) {
                this.innerValue.splice(index, 1);
                this.activeIndex = -1;
                this.$emit("update:detail");
              }
            })
            .catch(e => this.$helpers.showNotification(e.message))
            .finally(() => {
              this.deleting = false;
              this.deletedIndex = -1;
            });
        } else {
          this.innerValue.splice(index, 1);
          this.activeIndex = -1;
        }
      }
    }
  };
</script>

<style></style>
